<template>
    <div class="login-container">
        <unicom-linha>
            <unicom-coluna class="container-imagem" md="6" lg="7" v-if="tamanhoJanela > 425">
                <figure>
                    <img src="../../../public/assets/thumbnail_2-01.jpg" />
                    <div class="texto-giu">
                        <div class="fonte-primaria negrito textoLogin">
                            <p>Gestão</p>
                            <p>Institucional</p>
                            <p>Unimed</p>
                        </div>
                    </div>
                </figure>
            </unicom-coluna>

            <unicom-coluna class="container-login" md="6" lg="5">
                <unicom-linha espacamento justificarItem="center">
                    <unicom-coluna class="login" sm="11" md="10" lg="8">
                        <unicom-linha>
                            <unicom-coluna>
                                <div class="label-container" @click="voltar()">
                                    <p>Cancelar</p>
                                </div>
                            </unicom-coluna>
                        </unicom-linha>
                        <h2 class="fonte-primaria titulo negrito">Alterar minha senha</h2>
                        <ValidationObserver ref="alterarSenha">
                            <unicom-linha espacamento class="linha-login">
                                <unicom-coluna>
                                    <validation-provider rules="required" mode="eager" v-slot="{ errors }">
                                        <unicom-campo-texto
                                            v-model="senhaAtual"
                                            class="campo"
                                            :titulo="'Senha atual *'"
                                            :placeholder="'Insira a senha atual'"
                                            :mensagemErro="errors[0]"
                                            :tipoCampo="password_type['atual']"
                                        />

                                        <unicom-icone
                                            class="icone"
                                            :nome="password_icon['atual']"
                                            :tamanho="22"
                                            @click="toggleTypePassword('atual')"
                                        />
                                    </validation-provider>
                                </unicom-coluna>
                            </unicom-linha>

                            <unicom-linha espacamento class="linha-login">
                                <unicom-coluna>
                                    <validation-provider rules="required" mode="eager" v-slot="{ errors }">
                                        <unicom-campo-texto
                                            class="campo"
                                            v-model="senhaNova"
                                            :titulo="'Nova Senha *'"
                                            :placeholder="'Insira sua nova senha'"
                                            :mensagemErro="errors[0]"
                                            :tipoCampo="password_type['nova']"
                                        />

                                        <unicom-icone
                                            class="icone"
                                            :nome="password_icon['nova']"
                                            :tamanho="22"
                                            @click="toggleTypePassword('nova')"
                                        />
                                    </validation-provider>
                                </unicom-coluna>
                            </unicom-linha>

                            <unicom-linha espacamento class="linha-login">
                                <unicom-coluna>
                                    <p class="fonte-secundaria texto">A nova senha deve conter:</p>
                                    <div class="lista-validacao" v-for="(item, index) in listaValidacao" :key="index">
                                        <div :class="`icone-container ${validacaoConcluida(index)}`">
                                            <unicom-icone :nome="'check-circle'" :tamanho="14" />
                                        </div>
                                        <div
                                            :class="
                                                `fonte-secundaria texto label-container ${validacaoConcluida(index)}`
                                            "
                                        >
                                            {{ item.descricao }}
                                        </div>
                                    </div>
                                </unicom-coluna>
                            </unicom-linha>

                            <unicom-linha espacamento class="linha-login" justificarItem="between">
                                <unicom-coluna>
                                    <unicom-botao
                                        tipo="primario"
                                        @click="continuar()"
                                        bloco
                                        :desabilitado="liberarBotao"
                                    >
                                        ALTERAR SENHA
                                    </unicom-botao>
                                </unicom-coluna>
                            </unicom-linha>
                        </ValidationObserver>
                    </unicom-coluna>
                </unicom-linha>
            </unicom-coluna>
        </unicom-linha>

        <unicom-modal-sucesso
            v-model="mostrarModalSucesso"
            titulo="Senha alterada com sucesso!"
            @fechar="fechaModal()"
        />
    </div>
</template>

<script>
import RedefinirSenhaServiceMixin from "../../mixins/services/redefinirSenha/RedefinirSenhaServiceMixin";

import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
    components: {
        ValidationProvider,
        ValidationObserver
    },
    mixins: [RedefinirSenhaServiceMixin],
    data() {
        return {
            tamanhoJanela: 0,
            mostrarModalSucesso: false,

            username: localStorage.getItem("username"),

            senhaAtual: "",
            senhaNova: "",
            usuario: "",

            listaValidacao: [],

            password_icon: {
                atual: "eye",
                nova: "eye"
            },

            password_type: {
                atual: "password",
                nova: "password"
            }
        };
    },
    computed: {
        liberarBotao() {
            let erro = [];
            this.listaValidacao.forEach(item => {
                let regra = new RegExp(item.regra.slice(1, -1));
                erro.push(!(regra.test(this.senhaNova) && this.senhaAtual != "" && this.senhaNova != ""));
            });
            return erro.includes(true);
        }
    },
    created() {
        if (!this.username) this.voltar();

        window.addEventListener("resize", this.redimensionar);
        this.redimensionar();
    },
    beforeMount() {
        this.getListaValidacao();
    },
    destroyed() {
        localStorage.removeItem("username");
        window.removeEventListener("resize", this.redimensionar);
    },
    methods: {
        redimensionar() {
            this.tamanhoJanela = window.innerWidth;
        },
        voltar() {
            this.$router.push("/login");
        },
        getListaValidacao() {
            this.getRegrasValidacao().then(response => {
                this.listaValidacao = response.data;
            });
        },
        validacaoConcluida(index) {
            let regra = new RegExp(this.listaValidacao[index].regra.slice(1, -1));
            return regra.test(this.senhaNova) ? "ativo" : "";
        },
        async continuar() {
            let validacaoNovaSenha = await this.$refs.alterarSenha.validate();
            if (validacaoNovaSenha) {
                this.novaSenha();
            }
        },
        novaSenha() {
            const data = { username: this.username, oldPassword: this.senhaAtual, newPassword: this.senhaNova };

            this.postTrocarSenha(data).then(() => (this.mostrarModalSucesso = true));
        },
        fechaModal() {
            this.mostrarModalSucesso = false;
            this.voltar();
        },
        toggleTypePassword(campo) {
            if (this.toggle) {
                this.password_icon[campo] = "eye-off";
                this.password_type[campo] = "text";
            } else {
                this.password_icon[campo] = "eye";
                this.password_type[campo] = "password";
            }

            this.toggle = !this.toggle;
        }
    }
};
</script>

<style lang="scss" scoped>
.label-container {
    display: block;
    float: left;
    width: calc(100% - 24px);
    margin-bottom: 5px;

    p {
        color: #bebebe;
        margin-top: 3px;
        cursor: pointer;
    }
}

.lista-validacao {
    margin: 14px 0;
    box-sizing: border-box;
    height: 16px;
    color: #60656c;

    &:last-child {
        margin: 0;
    }

    .icone-container {
        float: left;
        box-sizing: border-box;
        width: 16px;
        height: 16px;

        &.ativo {
            color: #00995d;
        }
    }
}

.login-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 98vw;
    height: 100vh;
    box-sizing: border-box;
    position: fixed;

    .container-imagem {
        display: block;
        float: left;
        box-sizing: border-box;

        & img {
            width: 100vh;
            object-fit: fill;
        }

        .textoLogin {
            font-size: 58px;
            line-height: 58px;
            color: white;
        }

        .texto-giu {
            position: absolute;
            top: 32%;
            left: 40%;
            transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            text-align: left;
        }
    }

    .container-login {
        display: block;
        box-sizing: border-box;
        float: left;
        height: 100vh;
        background-color: #fff;

        overflow: auto;

        .login {
            margin: 40px 232px 0px 206px;
            height: 400px;

            h2 {
                font-size: 32px;
                line-height: 55px;
                color: #00995d;
            }
            .linha-login {
                margin-top: 24px;

                .campo {
                    width: 100%;
                }

                .icone {
                    position: absolute;
                    top: 33px;
                    right: 25px;
                    cursor: pointer;
                    color: #707070;
                }

                .lista-validacao {
                    margin: 14px 0;
                    box-sizing: border-box;
                    height: 16px;
                    color: #60656c;

                    &:last-child {
                        margin: 0;
                    }

                    .icone-container {
                        float: left;
                        box-sizing: border-box;
                        width: 16px;
                        height: 16px;

                        &.ativo {
                            color: #00995d;
                        }
                    }

                    .label-container {
                        float: left;
                        margin-left: 6px;
                        box-sizing: border-box;
                        width: calc(100% - 22px);

                        &.ativo {
                            text-decoration: line-through;
                            color: #00995d;
                        }

                        p {
                            color: #bebebe;
                            margin-top: 3px;
                            margin-left: 16px;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
}
</style>
