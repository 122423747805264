<template>
    <div>
        <GerarNovaSenha />
    </div>
</template>

<script>
import GerarNovaSenha from "../components/gerarNovaSenha/GerarNovaSenha.vue";
export default {
    components: {
        GerarNovaSenha
    }
};
</script>

<style lang="scss"></style>
